 import {deleteUser} from "@/api/api";

const actions = {
  async deleteCurrentUser({commit, state}) {
    if(state.activeUser.id) {
      commit("isLoading", true);
      commit("removeUser", state.activeUser.id);

      return await deleteUser(state.activeUser.id).then((response) => {
        commit("isLoading", false);
        commit("showHideDeleteConfirmPopup", false)
        commit("setActiveUser", null)
        return response;
      })
    }
  },
};

export default actions;

import axios from "axios";

const httpClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? window.location.origin
      : "http://localhost:8000",
});

export default httpClient;

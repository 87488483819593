import httpClient from "./httpConfig";

//END POINTS
const GET_USERS = "/users";
const UPDATE_USER = "/updateUser";
const DELETE_USER = "/deleteuser";
const ADD_USER = "/addUser";
const UPLOAD_FILE = "/uploadFile";
const LOGIN = "/login";
const VALIDATE_LOGIN = "/checkLogin";

const getUsers = () => {
  return httpClient.post(GET_USERS).catch(err => {
    console.log(err);
  });
};

const logIn = data => {
  return httpClient.post(LOGIN, data).catch(err => {
    console.log(err);
  });
};

const updateUser  = data => {
  return httpClient.post(UPDATE_USER, { ...data }).catch(err => {
    console.log(err);
  });
};

const validateLogin = data => {
  return httpClient.post(VALIDATE_LOGIN, data).catch(err => {
    console.log(err);
  });
};

const uploadFile = data => {
  return httpClient.post(UPLOAD_FILE, data).catch(err => {
    console.log(err);
  });
};

const addUser = data => {
  return httpClient.post(ADD_USER, { ...data }).catch(err => {
    console.log(err);
  });
};

const deleteUser  = id => {
  return httpClient.post(DELETE_USER,  {'id': id})
  .catch(err => {
    console.log(err);
  });
};


export {
  updateUser,
  getUsers,
  deleteUser,
  addUser,
  uploadFile,
  logIn,
  validateLogin
};

import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuex from 'vuex'
import 'es6-promise/auto'
import store from "./store";
import VueRouter from 'vue-router'
import { routes } from "./routes";
import JwPagination from 'jw-vue-pagination';

Vue.use(VueAxios, axios)
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.config.productionTip = false
Vue.component('jw-pagination', JwPagination);

const router = new VueRouter({ routes: routes});

router.beforeEach((to, from, next) => {
  //redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('token')

  if (authRequired && !loggedIn && to.path !== "/login")  {
    next('/login');
  } else {
    next();
  }

})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
<template>
  <!-- header-->
  <div class="Header">
    <img class="Header__logo" @click="toStart()" src="@/assets/logo.svg" />
    <div class="Header__cta" v-if="this.$store.state.isAdmin">
      <div class="userActions">
        <a href="#" @click="addNewUser()" class="userActions__btn is-add"
          >Add
          <img src="@/assets/icons/pluss.svg" />
        </a>
        <a href="#" class="userActions__btn" @click="logOut()">Log out</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toStart() {
      this.$router.push({
        path: `/`,
      });
    },
    addNewUser() {
      this.$router.push({
        path: `/adduser`,
      });
    },
    logOut() {
      localStorage.removeItem("token");
      this.$router.push({
        path: `/login`,
      });
      this.$store.commit("setAdmin", false);
    },
  },
};
</script>

<style lang="less">
//Header

.Header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6rem;

  &__logo {
    width: 8rem;
    cursor: pointer;

    @media screen and (min-width: 1240px) {
      width: 13rem;
    }
  }

  .userActions {
    margin-top: 0;
  }

  &__cta {
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
    }

    > img {
      width: 3rem;
      height: 3rem;
      margin-right: 0.5rem;
    }
  }
}
</style>


const mutations = {
  // setConfigurationData: (state, { payload, key }) => {
  //   const data = {
  //     [state.activeConfiguration]: {
  //       ...state.configurations[state.activeConfiguration],
  //       [key]: payload
  //     }
  //   };

  //   state.configurations = Object.assign({}, state.configurations, data);
  // },
  setUsers: (state, users) => {
    // mutate state
   state.users = users;
  },
  removeUser:(state, id) => {
    state.users = state.users.filter((user) => {
      return user.id !== id; 
    });
  },
  setAdmin: (state, statuss) => {
    state.isAdmin = statuss;
  },
  getStarIndex: (state, number) => {
    return 'star' + (6-number);
  },
  changeUserRating: (state, {id, rating}) => {
    state.users.map((user) => {
      if(user.id == id) {
        user.rating = rating;
      }
  })[0];
  },
  isLoading: (state, statuss) => {
    state.isLoading = statuss;
  },
  setActiveUser: (state, user) => {
    state.activeUser = user;
  },
  showHideDeleteConfirmPopup: (state, statuss) => {
    state.showPopup = statuss;
  },
  showConfirmationPopup: (state, user) => {
    state.showPopup = true;
    state.activeUser = user;
  }
}; 

export default mutations;

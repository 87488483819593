<template>
<div>

  <h1>Workers database</h1>
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In odio odio, finibus at orci at, porta ultrices orci.</p>

  <div v-if="users && users.length > 0" style="position: relative;   overflow-x: auto; isplay: flex; flex-direction: column;">
    <table :class="{ 'is-fullScreenTable': showFullRow !== null }">
      <thead>
        <tr :class="{ fullRow: showFullRow !== null }">
          <td>Full name</td>
          <td>Phone number</td>
          <td>Email</td>
          <td>City</td>
          <td>Position</td>
          <td>Busy</td>
          <td>Project</td>
          <td>Rating</td>
          <td>&nbsp;</td>
        </tr>
      </thead>
      <tbody>
        <tr
          :class="{ fullRow: showFullRow === rowIndex }"
          v-for="(user, rowIndex) in users"
          :key="rowIndex"
        >
          <td style="cursor:pointer;" @click="viewUser(user.id)">{{user.name}}</td>
           <td>{{user.phone}}</td>
           <td>{{user.email}}</td>
           <td>{{user.city}}</td>
           <td>{{user.position}}</td>
           <td>{{user.busy}}</td>
           <td>{{user.project}}</td>
           <td>  
            
             <fieldset class="user__rating" >
                <template v-for="(rating, index) in 5" >
                      <input style="pointer-events: none;" :key="rating" type="radio"  :id="`${rowIndex}${getStarIndex(rating)}`" :checked="user.rating == rating" :name="`${rowIndex}rating${getStarIndex(rating)}`" value="rating" />
                      <label style="pointer-events: none;" :key="rating + 's' + index" :for="`${rowIndex}${getStarIndex(rating)}`"></label>                                
                </template>
             </fieldset>
            </td>
          <!-- edit -->
          <td class="cta">
             <div
              @click="viewUser(user.id)"
              ><img src="@/assets/icons/view.svg"/></div>
            <div
              @click="editUser(user.id)"
              ><img src="@/assets/icons/edit.svg"/></div>
            <div
              v-show="showFullRow !== rowIndex"
              @click="showConfirmationPopup(user)"
              ><img src="@/assets/icons/delete.svg"/></div>
          </td>
        </tr>
      </tbody>
    </table>
    <jw-pagination :items="this.$store.state.users" @changePage="onChangePage"></jw-pagination>
  </div>
  <div v-else>
    <p style="font-weight: bold;
    margin: 23rem auto;
    text-align: center;
    font-size: 20px;">No users found</p>
  </div>
  <Popup v-if="showPopup">
    <h2>Are you sure?</h2>
    <p>You are trying to delete {{activeUser && activeUser.name}}</p>
    <div class="popup__btnWrapper">
      <div class="userActions__btn is-remove" @click="showHideDeleteConfirmPopup(false)">Cancel</div>
      <div class="userActions__btn is-save" @click="deleteCurrentUser()">Delete</div>
    </div>
  </Popup>
</div>

</template>

<script>
import {getUsers} from "@/api/api";
import { mapActions, mapMutations, mapState } from "vuex";
import Popup from "@/components/Popup"

export default {
  data() {
    return {
      showFullRow: null,
      responses: [],
      pageOfItems: [],
      users: null
    };
  },
  mounted() {
    getUsers().then((users) => {
     this.setUsers(users.data);
     this.users = users.data;
    })
  },
  components: {
    Popup
  },
  computed: {
    ...mapState(["showPopup", "activeUser"])
  },
  methods: {
    ...mapMutations(["setUsers", "removeUser", "showHideDeleteConfirmPopup", "showConfirmationPopup"]),
    ...mapActions(["deleteCurrentUser"]),
    onChangePage(users) {
        // update page of items
        this.users = users;
    },
    getStarIndex(number) {
      return 'star' + (6-number);
    },
    editUser(id) {
     this.$router.push(`/edit/${id}`);
    },
    viewUser(id) {
     this.$router.push(`/view/${id}`);
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
//pagination
.pagination {
  display: flex !important;

  .page-item {
    &.first, &.next, &.previous, &.last {
      display: none !important
    }

    &.active {
      background: #FF7D08!important;
      color: #fff !important;
      border-radius: 6px !important;
    }
  }
}

</style>
<style lang="less" scoped>

.user {
    &__rating {
        display: flex;
        cursor: default;
        flex-direction: row-reverse;
        justify-content: flex-end;
        border: 0;
        padding-left: 0;
        margin-left: 0;
        
        label {
            cursor: pointer;
           
        }

        input:checked ~ label {
            background-image: url('~@/assets/icons/star_filled.svg');          
        }
    
        &:not(:checked) {
            > label {
                background-image: url('~@/assets/icons/star.svg');
                background-size: 100%;
                 width: 2rem;
                height: 2.1rem;
                display: inline-block;
            }
        }

        input {
            display: none;
        }
    }
}

table {
  border-collapse: collapse;
  color: #141C25;
  font-size: 1.6rem;
  table-layout: fixed;
  text-align: left;
  margin: 4rem 0;
  width: 1024px;

   @media screen and (min-width:1240px) {
      width: 100%;
    }

  .cta {
    display: flex !important;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: center;

    div {
      cursor: pointer;
      &:first-child {
        margin-right: 1rem;
      }

      &:last-child {
        margin-left: 1rem;
      }
    }
  }

  td {
    padding: 12px 2px; 
   
      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding-left: 1rem;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding-bottom: 1.5rem;
      }
  }


  &.is-fullScreenTable {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 90%;
    margin: 2rem auto;
    box-shadow: 2px 2px 10px #e5e5e5;
    overflow: auto;

  }

  tbody {
    td {
      word-break: break-all;
      
      &::nth-child(2) {
        padding-left: 1rem;
      }

      &:first-child{
        color: #FF7D08;
        font-weight: bold;
      }      
    }

    tr {
      &:nth-child(even) {
        td {
          background: #F6F6F6;
        }  
      }
    }
  }

  thead {
    background: #f1f1f1;
    font-weight: bold;

    td {

      &:first-child {
        padding-left: 1rem;
      }

    }
  }
}
</style>

<template>
    <div id="app">
      <div class="Container">
        <Header admin="isAdmin" />
        <router-view :key="$route.fullPath"></router-view>  
      </div>
      <Footer/>
      <Loading />
    </div>
</template>

<script>
import Header from './components/core/Header';
import Footer from './components/core/Footer';
import Loading from './components/core/loading';
import {validateLogin} from "@/api/api";

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Loading
  },
  beforeCreate() {
    const token = localStorage.getItem('token');

     validateLogin({token: token}).then((response) => {
       if(!response.data) {
          this.$router.push('/login');
          localStorage.removeItem('token');
          this.$store.commit('setAdmin', false);
       } else {
        this.$store.commit('setAdmin', true);
       }
     })
  },
}

</script>

<style lang="less"> 

#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (min-width:1240px) {
     margin-top: 4rem;
    }

  .Container {
   margin: 2rem;
  
    @media screen and (min-width:1240px) {
      width: 1240px;
      margin: 6rem auto 4rem auto;
    }
    
  }
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: 3.6rem;
  text-align: left;
}

p {
  margin: 2rem 0;
  text-align: left;
}

body, html {
  font-size:62.5%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

body {
  font-size: 16px;
  color: #141C25;
}

input,
textarea,
label.as-input,
select {
   height: 3.8rem;
   border: 1px solid #D2D2D2;
   padding: 0 1.5rem;
   color: #141C25;
   font-size: 1.6rem;
   border-radius: .6rem;
   width: 100%;

   &.readOnly {
     border: none;
     outline: none;
     padding-left: 0;
   }

   &.as-fileUpload {
        color: #FF7D08;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: bold;
        cursor: pointer;
   }
}

select {
  background: #fff;
  &[disabled] {
    order: 0;
    appearance: none;
  }
}

textarea {
    min-height: 10rem;
    padding: 1rem;
    max-width: 100%;
    max-height: 20rem;
}

.userActions {
    display: flex;
    justify-content: flex-end;
    margin-top: 4rem;

    &__btn {
      border: 2px solid #141C25;
      color: #141C25;
      font-weight: bold;
      margin-left: 2rem;
      text-decoration: none;
      cursor: pointer;
      padding: 6px 7px;
       font-size: 1.5rem;
      border-radius: 5px;

      @media screen and (min-width:1240px) {
        font-size: 1.8rem;
        padding: 1.5rem 2.3rem;
        border-radius: 1rem;
      }

      img {
        display: none;
      }

      &.is-remove {
        display: flex;

        img {
          display: block;
          margin-left: .5rem;
          transform: rotate(45deg);
          }
      }

      &.is-add {
        display: flex;
        
        img {
          display: block;
          margin-left: .5rem;
        }
      }

      &.is-save {
        background: #FF7D08;
        color: #ffffff;
        border-color: transparent;
        min-width: 12.9rem;
        }
    }

    
}


</style>

const getters = {
  // getSteps: state => {
  //   return state.steps;
  // },
  isAdmin: state => {
    return state.isAdmin;
  },
};

export default getters;
